import React from "react"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import rond_color from "../assets/rond_color.svg"
// import { Link } from "gatsby"

import Navigation from "../components/Navigation"


const FAQ = () => (
    <div
        className="containerManifesto"
      >
        <div className=" container containerHome">
        <Navigation/>
        {/*<div className="rondTache row">
          <img src={rond} className="col-3 rond"/>
          <img src={tache} className="col-9 tache"/>
        </div>*/}
    <div>
    <SEO title="Plaiz - FAQ - Plaiz, le réseau social de la mode"/>
    <div>
    <h1 className="p1">FOIRE AUX QUESTIONS</h1>

    <h4
      style={styles.backToHomeLink}
    >
    <p className="textChangeGameLeft"><br/>
        <b>Bonjour et bienvenue sur le support! </b> <br/>
        <br/>
        Pour toutes questions dont la réponse ne se trouve pas ci-dessous, merci d'adresser un mail à l'adresse  ou un sms au 06 69 77 31 61. <br/>
        <br/><br/>​
        <b>Quel est le but de Plaiz ? </b> <br/>
        <br/>
        Nous avons entre 23 et 25 ans et nous croyons que c'est en donnant confiance aux jeunes générations et en les encourageant à s'exprimer qu'elles pourront changer les choses. <br/>
        <br/>
        Nous voulons que Plaiz soit un espace créatif et d'expression pour tous les passionnés de mode. <br/>
        <br/>
        Nous voulons rassembler la communauté qui impactera le monde créatif de demain. <br/>
        <br/>
        Plaiz est une application permettant à ses utilisateurs de se rejoindre une communauté de passionnés de mode afin d'échanger de manière Constructive (!!) sur des styles, looks, concepts... <br/>
        <br/>
        Nous encourageons la bienveillance entre utilisateurs et la liberté d'expression, dans la Limite Du Raisonnable (!!)<br/>
        <br/><br/>​<br/>​
        <b>Qui peut voir mes photos ? </b> <br/>
        <br/>
        Lors de l'inscription, on choisi un certain nombre de communautés. Les personnes qui appartienennt aux mêmes communautés que nous sont en mesure de voir toutes les photos postées par les membres de leur communauté. <br/>
        <br/><br/><br/>​
        <b>Qui peut voir les réactions que j'obtiens ? </b> <br/>
        <br/>
        Seule la personne qui a posté une photo est capable de voir le nombre de réactions qu'elle a obtenu. Cependant, tout le monde peut voir les commentaires et engager la conversation. <br/>
        <br/><br/>​​<br/>
        <b>Comment puis-je m'abonner à un compte ? </b> <br/>
        <br/>
        Sur Plaiz il n'y a pas d'abonnements, nous croyons que les membres de notre communauté devraient être jugés par leur créativité plutôt que par le nombre de followers qu'ils ont. <br/>
        <br/><br/>​<br/>​
        <b>Quel type de contenu puis-je voir et publier sur Plaiz? </b> <br/>
        <br/>
        Plaiz est un espace de création, d'inspiration et d'expression, nous sommes fiers de la qualité de nos membres. <br/>
        <br/>
        Nous souhaitons que la communauté reste libre de ses publications, cela dit, compte tenu de la diversité d'opinions nous sommes conscient que certains types de contenus peuvent heurter la sensibilité de nos membres. <br/>
        <br/>
        Nous demandons donc à ce que vous veilliez à ne pas publier de contenu indésirable ni d'image de nudité. <br/>
        <br/>
        Les images que nous jugeons inappropriées seront supprimées par l'équipe Plaiz et les modérateurs. <br/>
        <br/>
        Veillez également à rester courtois et constructifs en commentaires. Evidemment, nous ne sommes pas contre l'humour mais les propos violents seront lourdement sanctionnés. Le monde est déjà assez brutal pour que nous en rajoutions. <br/>
        <br/><br/><br/>​
        <b>Comment utiliser Plaiz? </b> <br/>
        <br/>
        Lors de la première inscription, nous te proposons de choisir des communautés. <br/>
        <br/>
        Tu pourras ensuite choisir un nom d'utilisateur et un nom de scène qui seront affichés sur ton profil et partout où tu commentera ou postera une photo. <br/>
        <br/>
        Depuis l'écran principal, tu pourras accéder: <br/>
        <br/>
        - Au fil d'actualité, sur lequel tu verras les publications de ta communauté. <br/>
        <br/>
        - A l'écran de notifications, sur lequel tu pourras être informé des interactions sur tes photos ou sur les photos auxquelles tu as réagi. <br/>
        <br/>
        - A l'écran d'ajout de photo, grâce tu pourras prendre et publier des photos, issues de ta caméra ou de ta bibliothèque. <br/>
        <br/>
        - A l'écran réglage, depuis lequel tu pourras accéder à la foire aux questions, à l'écran contacts, aux remerciements, aux conditions générales d'utilisation et à une petite surprise. Tu pourras également te déconnecter de l'application. <br/>
        <br/>
        - A l'écran profil, sur lequel tu pourras retrouver tes photos et tes favoris, ainsi que la possibilité de modifier ta photo de profil et ta biographie. <br/>
        <br/>
        Il est possible en cliquant sur "..." sur le fil d'actualité ou sur la vue plein-écran d'une publication de masquer une publication ou un individu, de bloquer la publication ou l'individu, de les signaler ou de supprimer la publication s'il s'agit de la tienne. <br/>
        <br/>
        Il est également possible de signaler un commentaire en cliquant dessus. <br/>
        <br/>
        Tu peux nous demander de supprimer toutes tes données en envoyant un mail à l'adresse hello@plaiz.io ou un sms au 06 69 77 31 61. <br/>
        <br/>
        Encore une fois, si tu as d'autres questions, c'est la même adresse mail et le même numéro :  hello@plaiz.io / 06 69 77 31 61 <br/>
        <br/>
        On te souhaite un grand kiff et une nouvelle fois, bienvenue dans la famille ! <br/>
        <br/><br/><br/>
        <b>Faites vous Plaiz ! </b> <br/>
        <br/>
        <b>Signé la team</b> <br/>
        </p>
    </h4>
    </div>
    </div>
    </div>
    </div>
)

export default FAQ

const styles = {
  backToHomeLink: {
    color: "white",
  },
}